import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import Layout from "../components/Layout";
import Background from "../components/Background";
import Section from "../components/Section";
import Hero from "../components/Hero";
import MotifColumn from "../components/MotifColumn";
import Questions from "../components/Questions";
import customBlogLink from "../utils/custom-blog-link";

import styles from "../css/blog-post-template.module.scss";
import categoryBusinessIcon from "../img/blog-category-business.jpg";
import categoryP2PIcon from "../img/blog-category-p2p.jpg";
import categoryTuitionIcon from "../img/blog-category-tuition.jpg";
import categoryNewsIcon from "../img/blog-category-news.jpg";

const BlogPost = data => {
  const {
    title,
    date,
    author,
    category,
    seo_description,
    body
  } = data.pageContext;
  let categoryIcon = () => {
    switch (category) {
      case "Business":
        return categoryBusinessIcon;
      case "P2P":
        return categoryP2PIcon;
      case "Tuition":
        return categoryTuitionIcon;
      case "News":
        return categoryNewsIcon;
      default:
        console.log("No icon");
    }
  };
  const dateObject = new Date(date);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let slices = [];

  if (body) {
    slices = body.map((slice, index) => {
      if (slice.type === "image") {
        return (
          <Col xs={12} lg={8} className={styles.postImage} key={index}>
            <img
              src={slice.primary.image.url}
              alt={slice.primary.image.alt}
              width="100%"
            />
            <p className={styles.photoCredit}>{slice.primary.photo_credit}</p>
          </Col>
        );
      } else if (slice.type === "text") {
        return (
          <Col xs={12} lg={8} className={styles.postText} key={index}>
            <RichText
              render={slice.primary.text}
              serializeHyperlink={customBlogLink}
              />
          </Col>
        );
      }

      return null;
    });
  }

  return (
    <Layout>
      <Helmet>
        <meta name="description" content={seo_description} />
      </Helmet>
      <Background>
        <Hero className={styles.blogPostHero}>
          <MotifColumn lg={8}>
            <h1>{title}</h1>
            <p className={styles.tagLine}>
              Written by <strong>{author}</strong> in{" "}
              <img
                className={styles.categoryIcon}
                src={categoryIcon()}
                width="25"
                height="25"
                alt=""
              />
              <strong>{category}</strong> on{" "}
              <strong>{`${
                monthNames[dateObject.getMonth()]
              } ${dateObject.getDate()}, ${dateObject.getFullYear()}`}</strong>
            </p>
          </MotifColumn>
        </Hero>
        <Section>
          <Row>{slices}</Row>
        </Section>
        <Questions />
      </Background>
    </Layout>
  );
};

export default BlogPost;
