import React from "react";
import { Link } from 'gatsby-plugin-intl';
import linkResolver from './link-resolver';

const customBlogLink = (type, element, content, children, index) => {
    const link = linkResolver(element.data);
    if(/(http:\/\/|https:\/\/)/.test(link)) {
        return <a key={index} href={link}>{content}</a>
    }
    return <Link key={index} to={link}>
            {content}
          </Link>
  }

  export default customBlogLink;
  